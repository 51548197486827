import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    file(relativePath: { eq: "Advocat.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Hero = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)
  return (
    <header className="hero">
      <div className="section-center hero-center">
        <article className="hero-info">
          <div>
            <div className="underline"></div>
            <h1>Меня зовут </h1>
            <h1>Грибков Андрей Вячеславович </h1>
            <div className="first">
              <h4 className="h4_first">Адвокат по Уголовным, Гражданским</h4>
              <h4>и Административным делам</h4>
              <h4>в Могилеве и Беларуси</h4>
            </div>
            <Link to="/contact" className="btn">
              Контакты
            </Link>
          </div>
        </article>
        <article class="hero-img">
          <Image fluid={fluid} className="hero-photo" />
        </article>
      </div>
    </header>
  )
}

export default Hero
