import React from "react"
import {
  FaBalanceScaleLeft,
  FaBoxOpen,
  FaBullhorn,
  FaMoneyBillWave,
  FaClipboardList,
  FaScroll,
  FaCarCrash,
  FaWrench,
  FaUserShield,
  FaHome,
} from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaBalanceScaleLeft className="service-icon" />,
    title: "Представление и защита Ваших интересов в суде",
  },
  {
    id: 2,
    icon: <FaMoneyBillWave className="service-icon" />,
    title: "Взыскание долгов",
  },
  {
    id: 3,
    icon: <FaBullhorn className="service-icon" />,
    title: "Консультации по правовым вопросам",
  },
  {
    id: 4,
    icon: <FaClipboardList className="service-icon" />,
    title: "Составление жалоб и исковых заявлений",
  },
  {
    id: 5,
    icon: <FaBoxOpen className="service-icon" />,
    title: "Ведение дел о разделе имущества",
  },
  {
    id: 6,
    icon: <FaScroll className="service-icon" />,
    title: "Дела о наследовании",
  },
  {
    id: 7,
    icon: <FaCarCrash className="service-icon" />,
    title: "Дела о ДТП",
  },
  {
    id: 8,
    icon: <FaWrench className="service-icon" />,
    title: "Трудовое право",
  },
  {
    id: 9,
    icon: <FaUserShield className="service-icon" />,
    title: "Защита прав потребителей",
  },
  {
    id: 10,
    icon: <FaHome className="service-icon" />,
    title: "Жилищное право",
  },
]
