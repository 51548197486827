import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Services from "../components/Services"
import SEO from "../components/SEO"
export default () => {
  return (
    <Layout>
      <SEO
        title="Адвокат Могилев"
        description="Консультации по правовым вопросам"
      />
      <Hero />
      <Services />
    </Layout>
  )
}
// ...GatsbyImageSharpFluid
